.contact-form__container {
    display: flex;
    justify-content: center;
  }
  
  .contact-form {
    width: 32rem;
    display: inline-flex;
    flex-direction: column;
    z-index: 5;
    font-family: 'Ubuntu', serif;
    background: linear-gradient(rgb(58, 123, 213), rgb(0, 210, 255));
    padding: 1rem;
    border-radius: 0.5rem;
    border: 2px ridge darkblue;
    height: auto;
    margin-bottom: 25vh;
  }
  
  .contact-form span {
    font-size: 1.5rem;
    margin-top: 1rem;
    margin-bottom: 0.25rem;
    color: white;
  }
  
  .contact-form input {
    height: 1.25rem;
    border-radius: 0.35rem;
    padding: 0.5rem;
  }
  
  .contact-form input.no-border-radius {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    flex-grow: 1;
  }
  
  .contact-form select {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
  
  .contact-form textarea {
    font-family: 'Ubuntu', sans-serif;
    padding: 1rem;
  }
  
  .contact-form button {
    align-self: center;
    display:inline-block;
    padding:0.5em 1.6em;
    margin:0 0.3em 0.3em 0;
    border-radius:2em;
    box-sizing: border-box;
    text-decoration:none;
    font-family:'Ubuntu',sans-serif;
    font-weight:300;
    color: #000000;
    background-color:#7accfc;
    text-align:center;
    transition: all 0.2s;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
  
  .contact-form button:hover {
    color:#000000;
    background-color:#4095c6;
    cursor: pointer;
  }
  
  @media (max-width: 768px) {
    .contact-form {
      width: auto;
    }
  }